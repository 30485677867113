'use client'

import { zodResolver } from '@hookform/resolvers/zod'
import { FunctionComponent, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as z from 'zod'

import { useMutation } from '../../../core/hook/useMutation'
import { sendContactEmailDocument } from '../../../queries/SendContactEmail.gql'
import { useTranslation } from '../../context/ConfigContext'
import { CountriesContext } from '../../context/CountriesContext'

import { Button } from '../Button/Button'
import { Modal } from './Modal'
import Icon from '../Icon/Icon'
import { Icons } from '../Icon/constants'
import { handleError } from '../../../core/hook/utils'
import { gtmPush } from '../../utils/gtmHelpers'
import { Civility } from '../Form/Civility'

const ModalContact: FunctionComponent<ModalInterface> = ({ uuid, onClose }) => {
  const { countries } = useContext(CountriesContext)
  const t = useTranslation()
  const [sendContact] = useMutation(sendContactEmailDocument)
  const [isSend, setSend] = useState<boolean>(false)

  const contactFormSchema = z.object({
    civility: z.string().nonempty({ message: t('This is a required field.', {}, false) }),
    country: z.string().nonempty({ message: t('This is a required field.', {}, false) }),
    email: z.string().email({
      message: t('Please enter a valid email address (Ex: johndoe@domain.com).', {}, false),
    }),
    firstname: z.string().nonempty({ message: t('This is a required field.', {}, false) }),
    lastname: z.string().nonempty({ message: t('This is a required field.', {}, false) }),
    message: z.string().nonempty({ message: t('This is a required field.', {}, false) }),
    subject: z.string().nonempty({ message: t('This is a required field.', {}, false) }),
  })

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<Contact>({
    resolver: zodResolver(contactFormSchema),
  })

  const gtmEvent = () => {
    gtmPush({
      event: 'contact_action',
      contact_action_type: 'contact_mail',
    })
  }

  const onSubmit = (data: Contact) => {
    sendContact({
      variables: {
        firstname: data.firstname,
        lastname: data.lastname,
        civility: data.civility,
        email: data.email,
        country: data.country,
        subject: data.subject,
        comment: data.message,
      },
    })
      .then((response) => {
        if (response.data?.sendContactEmail) {
          gtmEvent()
          setSend(true)
        }
      })
      .catch((error) => {
        handleError(error, false)
      })
  }

  return (
    <Modal uuid={uuid} dark={false} showLogo={false} anchor='center' onClose={onClose}>
      <div className='modal-contact'>
        <h2>{t('contact-popin-title', {}, true)}</h2>
        {isSend && (
          <div className='success'>
            <Icon iconName={Icons.SUCCESS} />
            <div>
              {t(
                "Thanks for contacting us with your comments and questions. We'll respond to you very soon",
                {},
                true,
              )}
            </div>
          </div>
        )}
        {!isSend && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='form-control'>
              <label htmlFor='civility'>
                {t('product_alert_popup_civility_placeholder', {}, true)}
              </label>
              <Civility register={register} name='civility' />
              {errors.civility && <p className='error-message'>{errors.civility.message}</p>}
            </div>
            <div className='form-control'>
              <label htmlFor='firstname'>{t('Firstname', {}, true)}</label>
              <input type='text' {...register('firstname')} />
              {errors.firstname && <p className='error-message'>{errors.firstname.message}</p>}
            </div>

            <div className='form-control'>
              <label htmlFor='lastname'>{t('Lastname', {}, true)}</label>
              <input type='text' {...register('lastname')} />
              {errors.lastname && <p className='error-message'>{errors.lastname.message}</p>}
            </div>

            <div className='form-control'>
              <label htmlFor='country'>{t('Country', {}, true)}</label>
              <select {...register('country')}>
                <option value=''>{t('Select your country', {}, true)}</option>
                {countries.map((country) => (
                  <option key={country?.id} value={country?.id || ''}>
                    {country?.full_name_locale}
                  </option>
                ))}
              </select>
              {errors.country && <p className='error-message'>{errors.country.message}</p>}
            </div>
            <div className='form-control'>
              <label htmlFor='email'>{t('Email', {}, true)}</label>
              <input type='email' {...register('email')} />
              {errors.email && <p className='error-message'>{errors.email.message}</p>}
            </div>
            <div className='form-control'>
              <label htmlFor='subject'>{t('Subject', {}, true)}</label>
              <select {...register('subject')}>
                <option value=''>{t('contact-subject-placeholder', {}, true)}</option>
                <option value='priceandproducts'>
                  {t('Information about product or price', {}, true)}
                </option>
                <option value='stores'>
                  {t('Information about our points of sales', {}, true)}
                </option>
                <option value='SAV'>{t('After-sale service', {}, true)}</option>
                <option value='catalog'>{t('Ask for a catalog', {}, true)}</option>
                <option value='exchange'>{t('Ask for an exchange', {}, true)}</option>
                <option value='other'>{t('Other', {}, true)}</option>
                <option value='remote'>{t('Remote appointment', {}, true)}</option>
              </select>
              {errors.subject && <p className='error-message'>{errors.subject.message}</p>}
            </div>
            <div className='form-control'>
              <label htmlFor='message'>{t('Comment Text', {}, true)}</label>
              <textarea id='message' rows={6} {...register('message')} />
              {errors.message && <p className='error-message'>{errors.message.message}</p>}
            </div>
            <Button type='submit' label={t('Submit', {}, false)} style='dark' />
          </form>
        )}
      </div>
    </Modal>
  )
}

export default ModalContact
