'use client'

import { createContext, ReactElement, useMemo } from 'react'

export type AvailableRegions = {
  id: number
  code: string
  name: string
}

export type Country = {
  id?: string | null
  full_name_locale?: string | null
  full_name_english?: string | null
  available_regions?: AvailableRegions[]
}

export const getRegionsByCountry = (countries: Country[], countryName: string) =>
  countries.find((country) => country?.id === countryName)?.available_regions ?? null

export const getRegionByNameFromCountries = (
  countries: Country[],
  countryName: string,
  regionName: string,
) => {
  const regions = getRegionsByCountry(countries, countryName)

  if (!regions) {
    return null
  }

  const selectedRegion = regions.find(
    (region) => region.name === regionName || region.code === regionName,
  )

  return selectedRegion ?? null
}

export const getRegionByIdFromCountries = (
  countries: Country[],
  countryName: string,
  regionId: string,
) => {
  const regions = getRegionsByCountry(countries, countryName)

  if (!regions) {
    return null
  }

  const selectedRegion = regions.find((region) => region.id.toString() === regionId)

  return selectedRegion ?? null
}

export type ConfigContextType = {
  countries: Country[]
}

export const CountriesContext = createContext<ConfigContextType>({
  countries: [],
})

const CountriesProvider = ({
  countries,
  children,
}: {
  countries: Country[]
  children: ReactElement
}) => {
  const context = useMemo(
    () => ({
      countries,
    }),
    [countries],
  )

  return <CountriesContext.Provider value={context}>{children}</CountriesContext.Provider>
}

export default CountriesProvider
